import {
  Alert,
  Box,
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import { OverviewBoxTextItem } from "../components/OverviewBoxTextItem";
import { withProjectSelector } from "../components/WithProjectBodyWrapper";
import {
  analyzeProposal,
  fetchOverview,
  postProposalPR,
  ProposalAnalysisResult,
  ProposalCombinedPackage,
  selectConnectivity,
  selectLatestResult,
  selectPendingResult,
  selectProposal,
} from "../features/proposalDetailSlice";
import { fetchRepoList, selectRepos } from "../features/repoListSlice";
import { OverviewBoxItem } from "../components/OverviewBoxItem";
import { FeatureFlagGate } from "../components/FeatureFlagGate";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import FormattedTimestamp from "../components/FormattedTimestamp";
import { CodeRepo } from "../components/CodeRepo";
import { ProposalVulnerability, ProposalState } from "../pb/edgebit/platform/v1alpha/source_repos_pb";
import { PlainMessage } from "@bufbuild/protobuf";
import VulnChip from "../components/VulnChip";
import React from "react";
import { ProposalStatus } from "../components/ProposalTable";

const MaxFailuresListed = 15;

export const ProposalDetail = withProjectSelector("Proposal Details", (props: { projectId: string }) => {
  const { repoId, proposalId } = useParams();
  const dispatch = useAppDispatch();
  const proposal = useAppSelector(selectProposal);
  const latestResult = useAppSelector(selectLatestResult);
  const pendingResult = useAppSelector(selectPendingResult);
  const connectivity = useAppSelector(selectConnectivity);
  const analysisState = useAppSelector((state) => state.proposalDetail.analysisState);
  const postingPr = useAppSelector((state) => state.proposalDetail.postingPr);
  const repos = useAppSelector(selectRepos);

  if (!repoId) {
    return <GlobalError message="No repository selected" />;
  }

  if (!proposalId) {
    return <GlobalError message="No proposal selected" />;
  }

  useEffect(() => {
    dispatch(fetchOverview({ projectId: props.projectId, repoId: repoId, proposalId: proposalId }));
    dispatch(fetchRepoList({ projectId: props.projectId }));
  }, [dispatch, props.projectId, repoId, proposalId]);

  return (
    <>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Proposals / {proposal && proposalName(latestResult)}
      </Typography>

      {proposal && proposal.result?.case === "prResult" ? (
        <EdgeBitPrimaryButton
          type="submit"
          variant="outlined"
          size="medium"
          sx={{ marginTop: "20px", marginBottom: "0px", marginRight: "20px" }}
          href={proposal.result.value.url}
        >
          View Pull Request #{proposal.result.value.id}
        </EdgeBitPrimaryButton>
      ) : (
        <EdgeBitPrimaryButton
          type="submit"
          variant="outlined"
          size="medium"
          sx={{ marginTop: "20px", marginBottom: "0px", marginRight: "20px" }}
          disabled={postingPr || !latestResult || latestResult.errorMessage.length > 0}
          onClick={() => {
            // Button shouldn't be clickable if this is null
            if (!latestResult) {
              return;
            }

            dispatch(
              postProposalPR({
                projectId: props.projectId,
                repoId,
                proposalId,
                analysisResultId: latestResult!.id,
              }),
            );
          }}
        >
          Create Pull Request
        </EdgeBitPrimaryButton>
      )}

      <Button
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "20px", marginBottom: "0px", marginRight: "20px" }}
        disabled={analysisState === "analyzing"}
        onClick={() => dispatch(analyzeProposal({ projectId: props.projectId, repoId, proposalId }))}
      >
        Re-Analyze Proposal
      </Button>

      <OverviewBox title="Details">
        {pendingResult && (
          <>
            <Alert
              severity="info"
              sx={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              New analysis started at {""}
              <FormattedTimestamp timestamp={pendingResult.createdAt} />
              <FeatureFlagGate flag="worker-job-debug">
                <>
                  <Link
                    to={`/debug/worker-job/${pendingResult.jobId}`}
                    component={NavLink}
                    sx={{ fontSize: "14px", paddingLeft: "5px" }}
                  >
                    View Logs
                  </Link>
                  <Box>Pending Job ID:{pendingResult.jobId}</Box>
                </>
              </FeatureFlagGate>
            </Alert>
          </>
        )}
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {latestResult?.completedAt && latestResult.errorMessage.length === 0 && (
              <>
                {latestResult.packages.length} dependency updates have been analyzed
                {latestResult?.assets?.safetyScore ? (
                  <>{" and impact to your app is rated " + latestResult?.assets?.safetyScore + ". "}</>
                ) : (
                  ". "
                )}
                {countVulnerabilities(latestResult.packages) > 0 && (
                  <Box sx={{ display: "inline-block" }}>
                    These updates fix {countVulnerabilities(latestResult.packages)} security issue
                    {countVulnerabilities(latestResult.packages) === 1 ? "" : "s"}.
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <OverviewBoxItem label="Updated Dependencies" emptyMessage="Unknown" isEmpty={!latestResult}>
              <Box sx={{ marginTop: "5px" }}>
                {repos ? <CodeRepo repo={repos.find((repo) => repo.id === proposal?.repoId)} /> : "Not selected"}
              </Box>
              <Table
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "2px",
                  marginTop: "10px",
                  "& td": {
                    padding: "2px 6px",
                  },
                }}
              >
                <TableBody>
                  {latestResult?.packages.map((pkg) => (
                    <TableRow key={pkg.name + pkg.updatedVersion}>
                      <TableCell>{pkg.name}</TableCell>
                      <TableCell>
                        {pkg.currentVersion.length === 0
                          ? pkg.updatedVersion + " (added)"
                          : pkg.currentVersion + " → " + pkg.updatedVersion}
                        <VulnList vulns={pkg.fixedVulnerabilities} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </OverviewBoxItem>
            <OverviewBoxItem
              label="Behavior Status"
              emptyMessage="No behavior determination"
              isEmpty={latestResult?.assets?.overallBehavior ? false : true}
            >
              {latestResult?.assets?.overallBehavior}
              <AIInfo />
            </OverviewBoxItem>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            sx={{
              textAlign: "right",
              "& iframe": {
                border: "0px",
                width: "100%",
              },
              "& img": {
                maxWidth: "100%",
                maxHeight: "300vh",
              },
            }}
          >
            {latestResult?.assets && (
              <Link href={latestResult.assets.svgUrl} target="_blank" rel="noreferrer">
                <img src={latestResult.assets.svgUrl} alt="Proposal Call Site Analysis" />
              </Link>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {latestResult && proposal && proposal.state && (
              <>
                <OverviewBoxItem label="Analysis Status" emptyMessage="Never" isEmpty={!latestResult}>
                  {proposal.state === ProposalState.ERRORED && (
                    <ErrorIcon
                      sx={{ color: "#ff0000", fontSize: "18px", verticalAlign: "bottom", marginRight: "3px" }}
                    />
                  )}
                  <ProposalStatus state={proposal?.state} />
                  {latestResult.errorMessage && <Box>{latestResult?.errorMessage}</Box>}
                  <FeatureFlagGate flag="worker-job-debug">
                    <Link
                      to={`/debug/worker-job/${latestResult.jobId}`}
                      component={NavLink}
                      sx={{ fontSize: "14px", display: "block" }}
                    >
                      View Logs
                    </Link>
                  </FeatureFlagGate>
                </OverviewBoxItem>

                <FeatureFlagGate flag="worker-job-debug">
                  <OverviewBoxTextItem label="Latest Job ID:" emptyMessage="Pending..." text={latestResult?.jobId} />
                </FeatureFlagGate>
              </>
            )}
            {!latestResult && (
              <OverviewBoxTextItem label="Analysis Status" emptyMessage="Pending..." text="Pending..." />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <OverviewBoxItem label="Last Analyzed" emptyMessage="Never" isEmpty={!latestResult}>
              <FormattedTimestamp timestamp={latestResult?.completedAt} />
            </OverviewBoxItem>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <OverviewBoxTextItem label="Proposal ID" emptyMessage="None" text={proposal?.id} />
          </Grid>
        </Grid>
      </OverviewBox>

      <OverviewBox title="Impact For Your App">
        <SymbolBehaviors content={latestResult?.callsiteSummary} />
      </OverviewBox>

      <OverviewBox title="Static Analysis For All Changes">
        <SymbolBehaviors content={latestResult?.symbolSummary} />
      </OverviewBox>

      <FeatureFlagGate flag="worker-job-debug">{latestResult && JobDetail(latestResult)}</FeatureFlagGate>
    </>
  );
});

const JobDetail = (result: ProposalAnalysisResult) => {
  let SimpleTable = (header: { left: string; right: string } | null, entries: any) => {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          {header && (
            <TableHead>
              <TableRow>
                <TableCell align="left">{header.left}</TableCell>
                <TableCell align="right">{header.right}</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>{entries}</TableBody>
        </Table>
      </TableContainer>
    );
  };
  let Row = (name: string, value: any, style: "plain" | "detail" | "tooltip") => {
    let rowProps = {
      "&:last-child td, &:last-child th": { border: 0 },
      ...(style === "detail" && { "&:hover": { cursor: "pointer" } }),
    };
    let cellProps = style === "tooltip" ? { padding: "2px 16px" } : { padding: "6px 16px" };

    return (
      <TableRow key={name} sx={rowProps}>
        <TableCell align="left" sx={cellProps}>
          {name}
        </TableCell>
        <TableCell align="right" sx={cellProps}>
          {value}
        </TableCell>
      </TableRow>
    );
  };

  let analysis = (
    <OverviewBox title="Analysis Metrics">
      {SimpleTable(
        { left: "Name", right: "Count" },
        Object.entries(result?.analysisMetrics?.counters || {}).map(([name, count]) => {
          return Row(name, count, "plain");
        }),
      )}
      {SimpleTable(
        { left: "Name", right: "Failures / Attempts" },
        Object.entries(result?.analysisMetrics?.attemptsFailures || {}).map(([name, records]) => {
          const FailuresTooltip = styled(({ className, ...props }: TooltipProps) => (
            <Tooltip {...props} classes={{ popper: className }} />
          ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
              maxWidth: "none",
              backgroundColor: theme.palette.common.white,
              color: "rgba(0, 0, 0, 0.87)",
              boxShadow: theme.shadows[1],
              fontSize: 11,
            },
          }));

          let attempts = records.attempts;
          let failures = Object.keys(records.failures).length;

          let info = failures ? (
            <>
              {SimpleTable(
                null,
                Object.entries(records.failures)
                  .sort((a, b) => b[1] - a[1])
                  .slice(0, MaxFailuresListed)
                  .map(([error, count]) => {
                    return Row(error, `${count}`, "tooltip");
                  }),
              )}
            </>
          ) : (
            <>No failures!</>
          );

          return (
            <FailuresTooltip key={name} title={info} placement="bottom-end">
              {Row(name, `${failures} / ${attempts}`, "detail")}
            </FailuresTooltip>
          );
        }),
      )}
    </OverviewBox>
  );

  let resources = (
    <OverviewBox title="Resource Usage">
      {SimpleTable(
        { left: "Description", right: "Value" },
        Object.entries(result?.resourceMetrics || {}).map(([name, value]) => {
          return Row(name, `${value}`, "plain");
        }),
      )}
    </OverviewBox>
  );

  return (
    <Grid container spacing={4}>
      <Grid item sm={12} md={6}>
        {analysis}
      </Grid>
      <Grid item sm={12} md={6}>
        {resources}
      </Grid>
    </Grid>
  );
};

// const IFrame = (props: { src: string }) => {
//   return (
//     <iframe
//       srcDoc={
//         "<html><body style='margin: 0'><img src=" +
//         props.src +
//         " style='maxWidth:100%; maxHeight: 100%;' /></body></html>"
//       }
//       style={{
//         border: "0px",
//         width: "100%",
//         minHeight: "275px",
//       }}
//     />
//   );
// };

const SymbolBehaviors = (props: { content: string | undefined }) => {
  if (!props.content || props.content.length === 0) {
    return <Box>Static analysis results not available yet.</Box>;
  }

  let added = "#4caf50";
  let removed = "#f44336";
  let refactored = "#2196f3";
  let bug_fix = "#ff9800";
  let undetermined = "#999";
  let incomplete = "#999";

  return (
    <Box
      sx={{
        "& .symbol-wrapper": {
          marginTop: "20px",
          marginBottom: "10px",
          padding: "20px 20px 10px 20px",
          borderRadius: "3px",
          border: "1px solid",
          position: "relative",
          fontSize: "12px",
        },
        "& .symbol-header": {
          position: "absolute",
          left: "20px",
          top: "-11px",
          border: "1px solid",
          borderRadius: "2px",
          background: "#fff",
        },
        "& .symbol-kind": {
          color: "#fff",
          padding: "0px 4px",
          display: "inline-block",
        },
        "& code": {
          fontFamily: "monospace",
          marginTop: "5px",
          background: "#ddd",
          padding: "2px 4px",
          borderRadius: "2px",
        },
        "& a": {
          marginTop: "5px",
          marginLeft: "5px;",
        },
        "& .symbol-meta": {
          color: "#333",
          padding: "0px 4px",
          background: "#fff",
          display: "inline-block",
          borderRight: "1px solid #333",
          "&:last-child": {
            borderRightWidth: "0px",
          },
        },
        "& .symbol-target": {
          marginLeft: "10px",
          marginTop: "5px",
          "&:before": {
            content: "'↳'",
            position: "relative",
            left: "-3px",
            top: "1px",
          },
        },
        "& .symbol-undetermined": {
          borderColor: undetermined,
          "& .symbol-kind": {
            background: undetermined,
          },
          "& .symbol-header": {
            borderColor: undetermined,
          },
          "& .symbol-meta": {
            borderColor: undetermined,
          },
        },
        "& .symbol-incomplete": {
          borderColor: incomplete,
          "& .symbol-kind": {
            background: incomplete,
          },
          "& .symbol-header": {
            borderColor: incomplete,
          },
          "& .symbol-meta": {
            borderColor: incomplete,
          },
        },
        "& .symbol-refactored": {
          borderColor: refactored,
          "& .symbol-kind": {
            background: refactored,
          },
          "& .symbol-header": {
            borderColor: refactored,
          },
          "& .symbol-meta": {
            borderColor: refactored,
          },
        },
        "& .symbol-bug_fix": {
          borderColor: bug_fix,
          "& .symbol-kind": {
            background: bug_fix,
          },
          "& .symbol-header": {
            borderColor: bug_fix,
          },
          "& .symbol-meta": {
            borderColor: bug_fix,
          },
        },
        "& .symbol-removed": {
          borderColor: removed,
          "& .symbol-kind": {
            background: removed,
          },
          "& .symbol-header": {
            borderColor: removed,
          },
          "& .symbol-meta": {
            borderColor: removed,
          },
        },
        "& .symbol-added": {
          borderColor: added,
          "& .symbol-kind": {
            background: added,
          },
          "& .symbol-header": {
            borderColor: added,
          },
          "& .symbol-meta": {
            borderColor: added,
          },
        },
        "& .symbol-disclaimer": {
          display: "inline-block",
          marginLeft: "5px",
          border: "1px solid #ccc",
          lineHeight: "11px",
          fontSize: "10px",
          fontWeight: "bold",
          color: "#999",
          borderRadius: "3px",
          padding: "0 3px",
          "&:hover": {
            cursor: "pointer",
          },
        },
        "& .symbol-filename": { fontFamily: "monospace", fontSize: "80%", marginTop: "5px" },
        "& .symbol-evaluation": { marginTop: "10px" },
      }}
      dangerouslySetInnerHTML={{ __html: props.content }}
    ></Box>
  );
};

const proposalName = (latestResult: ProposalAnalysisResult | null) => {
  if (!latestResult) {
    return "New Proposal";
  }
  const packages = latestResult?.changes.map((change) => change.name) || [];

  return "Update " + packages.join(", ");
};

const AIInfo = () => {
  return (
    <Tooltip title="Summarization made by AI, with inputs based on Git history and static analysis." placement="top">
      <Box
        sx={{
          display: "inline-block",
          marginLeft: "5px",
          border: "1px solid #ccc",
          lineHeight: "11px",
          fontSize: "10px",
          fontWeight: "bold",
          color: "#999",
          borderRadius: "3px",
          padding: "0 3px",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        AI
      </Box>
    </Tooltip>
  );
};

const VulnList = (props: { vulns: PlainMessage<ProposalVulnerability>[] }) => {
  return (
    <Box sx={{ marginTop: "3px" }}>
      {props.vulns.length > 0 && " fixes "}
      {props.vulns.map((vuln, index) => (
        <React.Fragment key={vuln.url}>
          <Link href={vuln.url} target="_blank" rel="noreferrer">
            <VulnChip preset={vuln.severity} innerText={vuln.severity} />
          </Link>
          {index < props.vulns.length - 1 ? ", " : ""}
        </React.Fragment>
      ))}
    </Box>
  );
};

const countVulnerabilities = (packages: ProposalCombinedPackage[]): number => {
  return packages.reduce((count, pkg) => {
    if (pkg.fixedVulnerabilities) {
      return count + pkg.fixedVulnerabilities.length;
    }
    return count;
  }, 0);
};
