import { PlainMessage } from "@bufbuild/protobuf";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";
import {
  GithubRepoDetails as PBGithubRepoDetails,
  SourceRepo as PBSourceRepo,
  SourceRepoPackage as PBSourceRepoPackage,
  Proposal as PBProposal,
  PRResult as PBPRResult,
  ProposalPackage as PBProposalPackage,
  PackageRef as PBPackageRef,
} from "../pb/edgebit/platform/v1alpha/source_repos_pb";

export type GithubRepoDetails = PlainMessage<PBGithubRepoDetails>;

export type RepoDetails =
  | { case: "githubRepoDetails"; value: GithubRepoDetails }
  | { case: undefined; value?: undefined };

export type SourceRepo = Omit<PlainMessage<PBSourceRepo>, "createdAt" | "updatedAt"> & {
  createdAt: string;
  updatedAt: string;
  details: RepoDetails;
};

export function sourceRepoFromPB(pb: PBSourceRepo): SourceRepo {
  return {
    ...pb,
    createdAt: pb.createdAt ? String(pb.createdAt.toDate().toISOString()) : "",
    updatedAt: pb.updatedAt ? String(pb.updatedAt.toDate().toISOString()) : "",
    details:
      pb.details.case === "githubRepoDetails"
        ? {
            case: "githubRepoDetails",
            value: {
              ...pb.details.value,
            },
          }
        : { case: undefined },
  };
}

export type SourceRepoPackage = PlainMessage<PBSourceRepoPackage>;

export const sourceRepoPackageFromPB = (pb: PBSourceRepoPackage): SourceRepoPackage => ({ ...pb });

export type Proposal = Omit<
  PlainMessage<PBProposal>,
  "packages" | "createdAt" | "updatedAt" | "result" | "proposalType"
> & {
  packages: ProposalPackage[];
  createdAt: string;
  updatedAt: string;
  result?: ProposalResult;
  proposalType: string;
};

export type PRResult = PlainMessage<PBPRResult>;

export type ProposalResult = { case: "prResult"; value: PRResult };

export type ProposalPackage = Omit<PlainMessage<PBProposalPackage>, "package"> & { package: PackageRef };

export type PackageRef = PlainMessage<PBPackageRef>;

export const proposalFromPB = (pb: PBProposal): Proposal => ({
  ...pb,
  packages: pb.packages.map(proposalPackageFromPB),
  createdAt: pb.createdAt ? pb.createdAt.toDate().toISOString() : "",
  updatedAt: pb.updatedAt ? pb.updatedAt.toDate().toISOString() : "",
  result: pb.result?.kind?.case ? { case: pb.result.kind.case, value: { ...pb.result.kind.value } } : undefined,
  proposalType: pb.proposalType ? pb.proposalType : "",
});

export const proposalPackageFromPB = (pb: PBProposalPackage): ProposalPackage => ({
  ...pb,
  package: { ...pb.package! },
});

export type PackageAndProposal = {
  package: SourceRepoPackage;
  proposal?: Proposal;
};

interface RepoDetailState {
  repo: SourceRepo | null;
  packages: PackageAndProposal[] | null;
  connectivity: boolean;
  scanning: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: RepoDetailState = {
  repo: null,
  packages: [],
  connectivity: true,
  scanning: false,
  status: "idle",
};

export const fetchRepo = createAsyncThunk<SourceRepo, { projectId: string; repoId: string }, ThunkExtra>(
  "repoDetail/fetchRepo",
  async ({ projectId, repoId }, thunkAPI) => {
    try {
      const { repoClient } = thunkAPI.extra;
      const response = await repoClient.getSourceRepo({ projectId, repoId });
      const repo = response.sourceRepo;

      if (!repo) {
        throw new Error("Repo not found");
      }

      return sourceRepoFromPB(repo);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const fetchPackagesAndProposals = createAsyncThunk<
  PackageAndProposal[],
  { projectId: string; repoId: string },
  ThunkExtra
>("repoDetail/fetchPackagesAndProposals", async ({ projectId, repoId }, thunkAPI) => {
  try {
    const { repoClient } = thunkAPI.extra;
    const response = await repoClient.listSourceRepoPackagesAndProposals({ projectId, repoId });
    return response.items.map((item) => {
      return {
        package: sourceRepoPackageFromPB(item.package!),
        proposal: item.proposal ? proposalFromPB(item.proposal) : undefined,
      };
    });
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const scanRepo = createAsyncThunk<void, { projectId: string; repoId: string }, ThunkExtra>(
  "repoDetail/scanRepo",
  async ({ projectId, repoId }, thunkAPI) => {
    try {
      const { repoClient } = thunkAPI.extra;
      await repoClient.triggerRepoScan({ projectId, repoId });
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const repoDetailSlice = createSlice({
  name: "repoDetail",
  initialState,
  reducers: {
    setConnectivity: (state, action: PayloadAction<boolean>) => {
      state.connectivity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRepo.pending, (state) => {
        state.status = "loading";
        state.repo = null;
      })
      .addCase(fetchRepo.fulfilled, (state, action) => {
        state.repo = action.payload;
        state.status = "idle";
        state.connectivity = true;
      })
      .addCase(fetchRepo.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(fetchPackagesAndProposals.pending, (state) => {
        state.status = "loading";
        state.packages = null;
      })
      .addCase(fetchPackagesAndProposals.fulfilled, (state, action) => {
        state.packages = action.payload;
      })
      .addCase(fetchPackagesAndProposals.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(scanRepo.pending, (state) => {
        state.scanning = true;
      })
      .addCase(scanRepo.fulfilled, (state) => {
        state.scanning = false;
      })
      .addCase(scanRepo.rejected, (state) => {
        state.scanning = false;
        state.connectivity = false;
        state.status = "failed";
      });
  },
});

export const selectConnectivity = (state: RootState) => state.repoDetail.connectivity;
export const selectRepo = (state: RootState) => state.repoDetail.repo;
export const selectPackages = (state: RootState) => state.repoDetail.packages;
export const selectScanning = (state: RootState) => state.repoDetail.scanning;

export default repoDetailSlice.reducer;
