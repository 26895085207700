import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";
import {
  selectProjectID,
  setProjectID,
  setSearch,
  selectOpenPullRequest,
  setOpenPullRequest,
  setTargetRepoID,
  fetchProposals,
  createProposal,
  selectCreatingProposal,
  selectTargetCategory,
  selectTargetRepoID,
  selectUpgradeCategoryModalOpen,
  closeUpgradeCategoryModal,
} from "../features/proposalListSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchRepoList, selectRepos } from "../features/repoListSlice";
import useProjectId from "../hooks/useProjectId";
import { useNavigate } from "react-router-dom";

interface DialogTriggerCategoryAnalysisProps {}

export function DialogTriggerCategoryAnalysis(props: DialogTriggerCategoryAnalysisProps) {
  const dispatch = useAppDispatch();
  const specificCategoryModalOpen = useAppSelector(selectUpgradeCategoryModalOpen);
  const openPullRequest = useAppSelector(selectOpenPullRequest);
  const repos = useAppSelector(selectRepos);
  const projectId = useAppSelector(selectProjectID);
  const category = useAppSelector(selectTargetCategory);
  const repoId = useAppSelector(selectTargetRepoID);
  const navigate = useNavigate();

  const handlePullRequest = () => {
    dispatch(setOpenPullRequest(!openPullRequest));
  };

  const handleCategoryModalClose = () => {
    dispatch(closeUpgradeCategoryModal());
  };

  const handleStartCategoryAnalysis = async () => {
    dispatch(setSearch(""));
    if (repoId && category) {
      await dispatch(
        createProposal({
          projectId: projectId,
          category: category,
          repoId: repoId,
          autoPropose: openPullRequest,
        }),
      );
    }
    dispatch(closeUpgradeCategoryModal());
    navigate("/proposals");
    dispatch(fetchProposals({ projectId: projectId }));
  };

  const setRepo = (event: SelectChangeEvent<any>) => {
    const repoId = event.target.value;
    dispatch(setTargetRepoID(repoId));
  };

  useProjectId((projectId: string) => {
    dispatch(setProjectID(projectId));
    dispatch(fetchRepoList({ projectId: projectId }));
    dispatch(fetchProposals({ projectId: projectId }));
  });

  return (
    <Dialog open={specificCategoryModalOpen} onClose={handleCategoryModalClose} aria-labelledby="alert-dialog-title">
      <DialogTitle id="alert-dialog-title">Start Upgrade Analysis</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A proposal will be created to upgrade {category?.upgradeCategory} dependencies plus any required transitive
          updates.
        </DialogContentText>

        <Grid sx={{ marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600", lineHeight: "40px" }}>
              <Box>Repository:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={6}>
              <FormControl
                sx={{
                  width: "100%",
                  maxWidth: 300,
                  backgroundColor: "#fff",
                  textAlign: "left",
                }}
              >
                <Select
                  name="repo-name"
                  displayEmpty
                  size="small"
                  onChange={setRepo}
                  disabled={repos === null}
                  value={repoId ? repoId : "Loading..."}
                >
                  {repos?.map((r) => (
                    <MenuItem key={r.displayName} value={r.id}>
                      {r.displayName}
                    </MenuItem>
                  ))}
                  {!repoId && <MenuItem value="Loading...">Loading...</MenuItem>}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: "0px" }}>
            <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600" }}>
              <Box>Category:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={6}>
              {category?.upgradeCategory}
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "10px" }}></Box>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            borderTop: "1px solid #ddd",
            marginTop: "20px",
            paddingTop: "18px",
            marginBottom: "0px",
          }}
        >
          Proposal Outputs
        </Typography>
        <FormGroup sx={{ verticalAlign: "top" }}>
          <FormControlLabel
            control={<Checkbox size="small" checked={true} disabled={true} />}
            label={"Detailed Analysis & Impact Report"}
          />
        </FormGroup>
        <FormGroup sx={{ verticalAlign: "top" }}>
          <FormControlLabel
            control={<Checkbox size="small" checked={openPullRequest} onChange={() => handlePullRequest()} />}
            label="Create Pull Request"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}>
        <EdgeBitPrimaryButton onClick={handleStartCategoryAnalysis} disabled={useAppSelector(selectCreatingProposal)}>
          Start Analysis
        </EdgeBitPrimaryButton>
        <Button tabIndex={-1} onClick={handleCategoryModalClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
