import {
  Box,
  Container,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";
import FormattedTimestamp from "./FormattedTimestamp";
import { SourceRepo } from "../features/repoDetailSlice";
import {
  fetchRepoList,
  selectConnectivity,
  selectRepos,
  selectSyncing,
  setSyncing,
  syncRepos,
} from "../features/repoListSlice";
import { GlobalError } from "./GlobalError";
import { CodeRepo } from "./CodeRepo";

export const RepoList = (props: { projectId: string }) => {
  const dispatch = useAppDispatch();
  const repoList = useAppSelector(selectRepos);
  const syncing = useAppSelector(selectSyncing);
  const connectivity = useAppSelector(selectConnectivity);

  useEffect(() => {
    dispatch(fetchRepoList({ projectId: props.projectId }));
  }, [dispatch, props.projectId]);

  const syncAndRefreshRepos = async () => {
    await dispatch(syncRepos({ projectId: props.projectId }));
    // This feels kind of janky but works OK
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await dispatch(fetchRepoList({ projectId: props.projectId }));

    dispatch(setSyncing(false));
  };

  return (
    <>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "0px", marginBottom: "0px", marginRight: "20px" }}
        disabled={syncing}
        onClick={syncAndRefreshRepos}
      >
        Sync Repos
      </EdgeBitPrimaryButton>
      {repoList && <RepoTable repos={repoList}></RepoTable>}
    </>
  );
};

export const RepoTable = (props: { repos: SourceRepo[] }) => {
  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Repository</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.repos?.map((repo: SourceRepo) => (
              <TableRow key={repo.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Link component={NavLink} to={"/repos/" + repo.id}>
                    <CodeRepo repo={repo} />
                  </Link>
                </TableCell>
                <TableCell>
                  <FormattedTimestamp timestamp={repo.createdAt} />
                </TableCell>
                <TableCell>
                  <FormattedTimestamp timestamp={repo.updatedAt} />
                </TableCell>
              </TableRow>
            ))}
            {props.repos.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "10px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      No repositories are being watched yet.
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      Repositories will display here soon after they are added, based on GitHub access controls.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
