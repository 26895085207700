import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { CodeRepo } from "./CodeRepo";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";
import {
  selectProjectID,
  selectTargetPkg,
  setProjectID,
  setSearch,
  selectOpenPullRequest,
  setOpenPullRequest,
  closeSpecificPackageModal,
  selectSpecificPackageModalOpen,
  fetchProposals,
  createProposal,
  selectCreatingProposal,
  selectTargetCategory,
} from "../features/proposalListSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchRepoList, selectRepos } from "../features/repoListSlice";
import useProjectId from "../hooks/useProjectId";
import { useNavigate } from "react-router-dom";

interface DialogTriggerPackageAnalysisProps {}

export function DialogTriggerPackageAnalysis(props: DialogTriggerPackageAnalysisProps) {
  const dispatch = useAppDispatch();
  const specificPackageModalOpen = useAppSelector(selectSpecificPackageModalOpen);
  const targetPkg = useAppSelector(selectTargetPkg);
  const openPullRequest = useAppSelector(selectOpenPullRequest);
  const repos = useAppSelector(selectRepos);
  const projectId = useAppSelector(selectProjectID);
  const category = useAppSelector(selectTargetCategory);
  const navigate = useNavigate();

  const handlePullRequest = () => {
    dispatch(setOpenPullRequest(!openPullRequest));
  };

  const handleSpecificPackageModalClose = () => {
    dispatch(closeSpecificPackageModal());
  };

  const handleStartPackageAnalysis = async () => {
    dispatch(setSearch(""));
    if (targetPkg && category) {
      await dispatch(
        createProposal({
          projectId: projectId,
          repoId: targetPkg.repoId,
          packageIds: [targetPkg.id],
          autoPropose: openPullRequest,
          category: category,
        }),
      );
    }
    dispatch(closeSpecificPackageModal());
    navigate("/proposals");
    dispatch(fetchProposals({ projectId: projectId }));
  };

  useProjectId((projectId: string) => {
    dispatch(setProjectID(projectId));
    dispatch(fetchRepoList({ projectId: projectId }));
    dispatch(fetchProposals({ projectId: projectId }));
  });

  return (
    <Dialog
      open={specificPackageModalOpen}
      onClose={handleSpecificPackageModalClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">Start Upgrade Analysis for {targetPkg?.packageName}</DialogTitle>
      {!targetPkg && specificPackageModalOpen && (
        <Alert style={{ marginTop: 11 }} severity="error">
          Select a package to analyze before continuing.
        </Alert>
      )}
      <DialogContent>
        <DialogContentText>
          A proposal will be created to analyze the upgrade for {targetPkg?.packageName} plus any required dependencies.
        </DialogContentText>

        <Grid sx={{ marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600" }}>
              <Box>Repository:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={6}>
              {targetPkg && repos ? (
                <CodeRepo repo={repos.find((repo) => repo.id === targetPkg.repoId)} />
              ) : (
                "Not selected"
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600" }}>
              <Box>Package:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={6}>
              {targetPkg ? targetPkg.packageName : "Not selected"}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600" }}>
              <Box>Current Version:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={6}>
              {targetPkg ? targetPkg.packageVersion : "Not selected"}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600" }}>
              <Box>Target Version:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={6}>
              Determined by update graph
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "10px" }}></Box>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            borderTop: "1px solid #ddd",
            marginTop: "20px",
            paddingTop: "18px",
            marginBottom: "0px",
          }}
        >
          Proposal Outputs
        </Typography>
        <FormGroup sx={{ verticalAlign: "top" }}>
          <FormControlLabel
            control={<Checkbox size="small" checked={true} disabled={true} />}
            label={"Detailed Analysis & Impact Report"}
          />
        </FormGroup>
        <FormGroup sx={{ verticalAlign: "top" }}>
          <FormControlLabel
            control={<Checkbox size="small" checked={openPullRequest} onChange={() => handlePullRequest()} />}
            label="Create Pull Request"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}>
        <EdgeBitPrimaryButton onClick={handleStartPackageAnalysis} disabled={useAppSelector(selectCreatingProposal)}>
          Start Analysis
        </EdgeBitPrimaryButton>
        <Button tabIndex={-1} onClick={handleSpecificPackageModalClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
